<template>
  <PageTitle title="Создание инициативы о применении мер таможенно-тарифного регулирования" mini-indentation />

  <div class="container-page big">
    <TabStep
      :components="components"
      confirm-button="Создать заявление"
      :cancel-route="{ name: 'RequestInitiativeTtrRegistry' }"
      @step="scrollTop"
    />
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  import PageTitle from '@/common/components/redesigned/PageTitle.vue';
  import TabStep from '@/common/components/redesigned/Tabs/TabsSteps/TabStep.vue';
  import Utils from '@/common/utils';

  import InitiativeJustificationMeasure from '../components/initiativeComponents/initiativeForm/InitiativeJustificationMeasure.vue';
  import InitiativeMainInfo from '../components/initiativeComponents/initiativeForm/InitiativeMainInfo.vue';
  import InitiativeRate from '../components/initiativeComponents/initiativeForm/initiativeRate/InitiativeRate.vue';

  export default {
    components: {
      PageTitle,
      TabStep,
    },
    data() {
      return {
        components: [
          {
            component: defineComponent(InitiativeMainInfo),
            props: { tabTitle: 'Основная информация' },
          },
          {
            component: defineComponent(InitiativeRate),
            props: { tabTitle: 'Ставки' },
          },
          {
            component: defineComponent(InitiativeJustificationMeasure),
            props: { tabTitle: 'Обоснование предлагаемой меры' },
          },
        ],
      };
    },
    methods: {
      scrollTop() {
        Utils.windowTop();
      },
    },
  };
</script>

<style lang="scss" scoped></style>
